<template>
    <div class="bg-noise w-full flex items-center b">
  <div class="max-w-6xl mx-auto px-4 sm:px-10 lg:px-0 flex flex-col gap-4 ">
    <!-- Title -->
    <h1 class="text-6xl pt-10 pb-2  text-CBlauw">Over mij</h1>
    <h4 class="px-2 md:px-0 text-xl font-semibold">Hi, Ik ben Floris Wierenga, allround grafisch ontwerper.
    </h4>
    <div class="md:max-w-[50%]">
<p>Mijn passie ligt in het ontwerpen van diverse digitale producten die perfect aansluiten bij de behoeften van de gebruiker. Elk probleem vraagt om een doordachte oplossing, die ik ontwikkel door diepgaand onderzoek naar de gebruiker en zijn wensen.</p>    
<br><p>Dankzij mijn brede expertise in grafisch ontwerp, webdevelopment en UI/UX weet ik functionele en visueel aantrekkelijke producten te creëren die naadloos aansluiten bij de juiste doelgroep.</p>
</div>
    <div class="px-2 md:px-0 w-full flex flex-col lg:flex-row gap-6 sm:gap-4 lg:gap-6 justify-between">
      <!-- Col 1 -->
      <div class="w-full flex flex-col items-stretch gap-4">
        <!-- col 1 -->
        <div class="flex flex-col sm:flex-row gap-2 md:gap-6 items-center justify-between ">
          <!-- 1 -->
          <ul class="w-full text-gray-90">
            <li class="py-2 border-b border-gray-200"><span class="font-bold">Werkervaring</span></li>
            <li class="py-2 border-b border-gray-200"><span class="font-bold">High On Art</span>
              <P>Multimediaal kunstenaars assistent</P>
              <p class="italic">2020/2019</p></li>
            <li class="py-2 border-b border-gray-200"><span class="font-bold">DVJ Insights</span>
              <p>Webdesigner/Grafisch vorgever</p>
              <p class="italic">2017/2019</p></li>
             
            <li class="py-2 border-b border-gray-200"><span class="font-bold">Bannersetje/House of Web</span>
              <p>Flash Animator/Webdesigner</p>
              <p class="italic">2014/2015</p></li>
          </ul>
          <!-- 2 -->
          <ul class="w-full text-gray-900">
            <li class="py-2 border-b border-gray-200"><span class="font-bold">Opleidingen</span></li>
            <li class="py-2 border-b border-gray-200"><span class="font-bold">Mediavomgeving/Webdesign MBO4</span><br>
              <p class="italic">2013/2017</p></li>
            <li class="py-2 border-b border-gray-200"><span class="font-bold">Communication and Multimedia Design - Bachelor</span><br>
              <p class="italic">2018/2022</p></li>
          </ul>
          
        </div>
        <!-- col 2 Buttons -->
        <div class="flex gap-4 justify-center sm:justify-start">
         <button class="p-2 px-6 text-CBlauw bg-blue font-semibold  border-2 border-CBlauw hover:bg-CRoze hover:text-white transition ease-in-out">
                    Email me
                </button>
                <button class="p-2 px-6 text-CBlauw font-semibold  border-2 border-CBlauw hover:bg-CRoze hover:text-white transition ease-in-out">
                    Download CV
                </button>
        </div>

        
      </div>
  
  <!-- Adobe Software Icons -->
  <div class="w-full h-20 lg:w-1/2 flex flex-wrap gap-6 justify-center ">
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/ps_ico.png" title="Adobe Photoshop" alt="Photoshop Logo"/>
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/ai_ico.png" title="Adobe Illustrator" alt="Illustrator Logo"/>
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/id_ico.png" title="Adobe InDesign" alt="InDesign Logo"/>
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/pr_ico.png" title="Adobe Premiere Pro" alt="Premiere Pro Logo"/>
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/au_ico.png" title="Adobe Audition" alt="Audition Logo"/>
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/ae_ico.png" title="Adobe After Effects" alt="After Effects Logo"/>
  </div>

  <!-- Development & 3D Icons -->
  <div class="w-full h-20 lg:w-1/2 flex flex-wrap gap-5 justify-center">
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/vue_ico.png" title="Vue.js" alt="Vue.js Logo"/>
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/wordpress_ico.png" title="WordPress" alt="WordPress Logo"/>
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/tailwind_ico.png" title="Tailwind CSS" alt="Tailwind Logo"/>
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/git_ico.png" title="Git" alt="Git Logo"/>
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/blender_ico.png" title="Blender 3D" alt="Blender Logo"/>
    <img class="w-12 sm:w-16 object-contain max-h-20" src="../assets/icos/unity_ico.png" title="Unity 3D" alt="Unity Logo"/>
  </div>
</div>

<div class="pb-20"></div>
      
    
  </div>
</div>

    
    </template>
    
    <script>
    export default {
      name: 'InfomationSkills',
      props: {
        msg: String,
      },
    };
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
   
    </style>
    