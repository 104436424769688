    <template>
    <div class="bg-noise">
      <div class="bg-CRoze bg-noise">
      <div class="max-w-6xl m-auto relative pt-10 pb-12 md:px-5 lg:px-0">
      <h1 class="text-center text-5xl md:text-left text-CBlauw">De Pijvrij Coach</h1>
    <hr class="m-auto mt-3 w-48 h-2 mb-2 rounded-md bg-CRoodf md:m-0 md:mt-2">
          <p class="category text-center md:text-left ">Beeld&Geluid <br/> Motion Graphics</p>
          <p class="text-center max-w-2xl md:text-left introtext pt-5">In samenwerking met een editor en een cameraman hebben we drie volledige video’s geproduceerd voor De Pijnvrij Coach, Bas Vermeulen. <br/> <br/> 
            Deze video’s zijn ontworpen om cliënten te informeren en enthousiasmeren over zijn unieke behandelmethodes. Mijn rol binnen dit project was veelzijdig.
          </p>
        </div>
      </div>
      <div class="py-10 bg-noise">
        <div class="max-w-6xl m-auto relative md:px-5 lg:px-0">
          <div class="space-y-8 md:space-y-10 text-center md:text-left  md:justify-center md:items-center md:flex  ">
          <div class="w-full md:w-1/4 space-y-4  md:mr-10 lg:pb-40">
            <h3>Het soepele spieren traject</h3>
                        <p>
                          Ik was verantwoordelijk voor het opnemen en optimaliseren van de audio, het bewaken van de juiste kadrering en visuele compositie tijdens de opnames, en het ontwerpen en animeren van alle visuele elementen in Adobe After Effects. </p><p>
                          De animaties speelden een cruciale rol; ze versterkten de uitleg van de behandelmethodes en zorgden voor een levendige, aantrekkelijke presentatie, waardoor de inhoud zowel informatief als visueel boeiend werd.
                         </p>
                  </div>
            
                  <div class="mx-auto w-full md:w-3/4">
              <video class="h-full w-full " controls>
      <source
        src="../../assets/projects/pijnvrijcoach/pijnvrijcoach.mp4"
        type="video/mp4"
      />

    </video>
              <div class="description-text-right">Voorkant visite kaartje</div>
            </div>
        </div>
            
        </div>
        </div>
   
    
    </div>
    </template>

    <script>
    export default {
      name: 'pijnvrijcoach_project',
      props: {
        msg: String,
      },
    };
    </script>

    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    h {
      margin: 40px 0 0;
      font-size:9rem;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #634e3b;
      font-family: "Host Grotesk", serif;
    }
    </style>
