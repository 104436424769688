<template>
<div class="bg-noise">
<footer class="flex flex-col justify-center p-10">

    

<div class="flex justify-center space-x-5">
    <a href="mailto:floris.wierenga@gmail.com">
        <img src="..\assets\Logo_Mail.png" />
    </a>
    <a href="https://www.instagram.com/floringas/" target="_blank" rel="noopener noreferrer">
        <img src="..\assets\Logo_Insta.png" />
    </a>
    <a href="https://www.linkedin.com/in/floris-wierenga/" target="_blank" rel="noopener noreferrer">
        <img src="..\assets\Logo_LinkedIn.png" />
    </a>
    
</div>
<div class="w-[130px] pt-5 m-auto"><img src="@/assets/FloringasZwart.png" class="w-full inline-block " /></div>
<p class="text-center text-gray-700 font-medium pt-5">&copy; Floris Wierenga 2025.</p>

</footer>
</div>
</template>

<script>
export default {
  name: 'FooterSocial',
  props: {
    msg: String,
  },
};
</script>
