<template>
  <div id="Spacer">
  <div class="bg-white sticky top-0 z-[10]" ><NavigationBar/></div>
  <router-view/>
  <FooterSocial/>
  </div>
</template>

<script>
import '@/assets/tailwind.css';
import NavigationBar from '@/components/NavigationBar.vue';
import FooterSocial from '@/components/FooterSocial.vue';


export default {
  name: 'App',
  components: {
    NavigationBar,
    FooterSocial,
  }
}
</script>

<style>



</style>
