<template>
<div class="bg-noise lg:h-full">
<div class="w-[400px] md:w-full pt-5 max-w-6xl m-auto relative md:pt-20 ">
    <div class="pb-20 lg:pb-0 flex flex-col-reverse lg:flex-row gap-6 justify-between items-center h-[30%] ">
        <!-- Text Section (Left) -->
        <div class="md:w-[650px] lg:w-[500px] items-center text-center lg:ml-5 text-grey-700 flex flex-col justify-end ">
            <h4 class="text-3xl px-5 py-5 text-CBlauw font-medium w-full bg-CRoze ">
              Hi, mijn naam is
                <span class=" text-white leading-510 "><br>Floris Wierenga</span>
            </h4>
            <div class="h-[50px] lg:h-[150px] md:w-[400px] inline-block flex-none ">
            <h1 class="text-3xl mt-4 text-CBlauw">
                Ik ben een <span ref="typing" class="typing text-gray-900"></span>
            </h1>
          </div>
        
              <p class="text-center italic pt-10 text-base">
             Kijk gerust verder naar de pojecten waar ik het meest trots op ben.
            </p>
            <div class="mt-6 flex sm:flex-row flex-col gap-4 lg:justify-start justify-center">
                <button class= "p-2 px-6 text-CBlauw bg-blue font-semibold  border-2 border-CBlauw hover:bg-echtroze hover:text-white transition ease-in-out">
                    <a href="mailto:floris.wierenga@gmail.com">Email me</a>
                </button>
                <a href="CV_FlorisWierenga_Public.pdf" download>
                <button class="p-2 px-6 text-CBlauw font-semibold  border-2 border-CBlauw hover:bg-CBlauw hover:text-white transition ease-in-out">
                 Download CV
                </button>
              </a>
              </div>
              
        </div>

        <!-- Image Section (Right) -->
        <div class="lg:w-1/2 w-full flex justify-center">
            <img 
                class="lg:max-w-[97%] md:max-w-[50%] "
                :src="require('@/assets/FlorisProfile_LightBlue.png')" 
                alt="Floris"
            />
        </div>
    </div>
</div>

</div>
</template>

<script>
import { onMounted, ref } from "vue";
import Typed from "typed.js";

export default {
  setup() {
    const typing = ref(null);


    onMounted(() => {
      new Typed(typing.value, {
        strings: [
        "CMD-er",  
        "Grafisch Ontwerper",
        "UI/UX Designer",
        "webdeveloper",
          " ",
        ],
        typeSpeed: 100,
        backSpeed: 30,
        loop: true,
      });
    });

    return {
      typing,
  
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h {
  margin: 40px 0 0;
  font-size:9rem;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}


h1, h2, h3, h4 {
  font-family: "heavitas", serif;
}

</style>
