<template>
<Opdebeen_project/>
</template>


<script>
// @ is an alias to /src
import '@/assets/tailwind.css';
import Opdebeen_project from '@/components/projects/opdebeen_project.vue';


export default {
  name: 'pijnvrijcoach_project',
  components: {
   Opdebeen_project
  }
}
</script>



