    <template>
    <div class="bg-noise">
      <div class="bg-CRoze bg-noise">
      <div class="max-w-6xl m-auto relative pt-10 pb-12 md:px-5 lg:px-0">
      <h1 class="text-center text-5xl md:text-left text-CBlauw">Visuele Identiteit voor Tattooist Stan Linders</h1>
    <hr class="m-auto mt-3 w-48 h-2 mb-2 rounded-md bg-CRoodf md:m-0 md:mt-2">
          <p class="category text-center md:text-left ">Band identity / print</p>
          <p class="text-center max-w-3xl md:text-left introtext pt-5">Tattooist Stan Linders kwam naar mij toe met de vraag hoe hij een sterkere impact kon achterlaten op zijn cliënten. 
            Op zijn sociale kanalen was geen consistente brand identity te herkennen, wat ten koste ging van zijn herkenbaarheid. 
            Om dit probleem op te lossen, heb ik voorgesteld om visitekaartjes en stickers te ontwerpen.
          </p>
        </div>
      </div>
      <div class="py-10 bg-noise">
        <div class="max-w-6xl m-auto relative md:px-5 lg:px-0">
          <div class="space-y-8 md:space-y-10 text-center md:text-left  md:justify-center md:items-center md:flex  ">
          <div class="w-full md:w-1/4 space-y-4  md:mr-10 lg:pb-40">
            <h3>Strategie en aanpak</h3>
                        <p>
                          e visitekaartjes zorgen ervoor dat zijn branding op een makkelijke en visuele manier van persoon tot persoon wordt verspreid—een soort word-of-mouth marketing, maar dan fysiek. De stickers vormen een passieve guerrilla-marketingcampagne doordat ze via de juiste personen en locaties verspreid kunnen worden.
                          </p><p>Om tot een passend design te komen, heb ik eerst goed gekeken en geluisterd naar wat Stan uniek maakt en wat zijn wensen waren. Na verschillende schetsen kwam ik uit op een nostalgische jaren ‘60 hippie vibe, met felle kleuren, bolle letters en expressionistisch lijnwerk. 
                          Dit heb ik vervolgens versterkt door inspiratie te halen uit het beroemde kunstwerk "De Grote Golf van Kanagawa" van de Japanse kunstenaar Hokusai Katsushika.
                        </p>
                      
                    </div>
            <div class="mx-auto w-4/5 md:w-3/4">
              <img class="w-full" src="../../assets/projects/stanlinders/StanLinders_VK.png"/>
              <div class="description-text-right">Voorkant visite kaartje</div>
            </div>
        </div>
            
        </div>
        </div>
    <div class="bg-CRoze py-10">
      <div class="max-w-6xl m-auto relative md:px-5 lg:px-0">
        <div class="space-y-8 md:space-y-0 text-center md:text-left md:justify-center  md:flex md:items-center md:flex-row-reverse ">
          <div class="w-full md:w-1/4 space-y-4  md:mr-10 lg:pb-40 md:ml-10">
          <h3>Ontwerp en uitvoering</h3>
                        <p>
                          De stickers zijn zo ontworpen dat ze opvallen, ongeacht waar ze worden geplakt. De stijl hiervan is vervolgens doorgetrokken naar de visitekaartjes, zodat er een consistente visuele identiteit ontstaat.
                          Het uiteindelijke design is vanuit de schetsen uitgewerkt in Adobe Illustrator. De visitekaartjes zijn gedrukt op 350 grams papier met een wat ruwer textuur, wat goed aansluit bij de uitstraling van Stan. 
                          Daarnaast heb ik een branding kit ontwikkeld voor gebruik op zijn socials en website, zodat zijn identiteit ook digitaal eenduidig blijft.
                        </p>
                    </div>
            <div class="mx-auto w-4/5 md:w-3/4"  >
              <img class="w-full" src="../../assets/projects/stanlinders/visite_achterkant.png"/>
              <div class="description-text-left">achterkant visite kaartje</div>
            </div>
          </div>
        </div>
        
        </div>
        <div class="py-10 bg-noise">
        <div class="max-w-6xl m-auto relative md:px-5 lg:px-0">
          <div class="space-y-8 md:space-y-10 text-center md:text-left  md:justify-center md:items-center md:flex  ">
          <div class="w-full md:w-1/4 space-y-4  md:mr-10 lg:pb-40">
            <h3>Resultaat en impact</h3>
                        <p>
                          Een half jaar na de introductie van de visitekaartjes en stickers hoorde ik dat het Instagram-account van Stan met 130% was gestegen ten opzichte van de maanden ervoor. Dit laat zien dat de branding en offline promotie een directe impact hebben gehad op zijn online aanwezigheid.
                        </p>
                      
                    </div>
            <div class="mx-auto w-2/4 md:w-1/4">
              <img class="w-full" src="../../assets/projects/stanlinders/StanLinders_Vsticker.png"/>
              <div class="description-text-right">Sticker vierkant.</div>
            </div>
            <div class="mx-auto w-2/4 md:w-1/4">
              <img class="w-full" src="../../assets/projects/stanlinders/StanLinders_Rsticker.png"/>
              <div class="description-text-right">Sticker rond.</div>
            </div>
        </div>
            
        </div>
        </div>
    
    </div>
    </template>

    <script>
    export default {
      name: 'stanlinders_project',
      props: {
        msg: String,
      },
    };
    </script>

    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    h {
      margin: 40px 0 0;
      font-size:9rem;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #634e3b;
      font-family: "Host Grotesk", serif;
    }
    </style>
