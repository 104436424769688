    <template>
    <div class="bg-noise bg-CRoze ">
    <div class="max-w-6xl m-auto h-full relative">
        <h1 class="ml-2.5 text-6xl pt-10 pb-2  text-CBlauw">Projects</h1>
        <div class="mx-2.5 pb-3 flex flex-col md:grid md:grid-cols-3 gap-3">
            <div class="relative overflow-hidden group">
                <img src="../assets/projects/thumbs/lamb_thumb.png" alt="Lamb_mockup" 
                        class="w-full transition duration-500 ease-in-out group-hover:opacity-60">
                    <div class="absolute text-left bottom-0 left-0 h-2/3 w-full flex flex-col justify-center p-5 space-y-1
                    transition duration-300 ease-in-out group-hover:scale-105">
                        <h2 class="text-6xl font-portcard bg-CGeel/90 px-3 py-2 w-max">LAMB</h2>
                        <p class="text-md text-CGeel bg-black/70 px-2 py-1 w-max">Wodka Branding</p>
                        <p class="text-xs text-CGeel font-sans bg-CBlauw/80 px-2 py-1 w-max">Graphic design</p>
                    </div>
                      <router-link 
                      to="/lamb_project" 
                      aria-label="Port-item" 
                      class="absolute text-left inset-0">
                      </router-link>
                  </div> 
                  <!-- StanLinders -->
                  <div class="relative overflow-hidden group">
                    <img src="../assets/projects/thumbs/StanLindersThumb.png" alt="Lamb_mockup" 
                        class="w-full transition duration-500 ease-in-out group-hover:opacity-60">
                    <div class="absolute text-left bottom-0 left-0 h-2/3 w-full flex flex-col justify-center p-5 space-y-1
                    transition duration-300 ease-in-out group-hover:scale-105">
                        <h2 class="text-6xl font-portcard bg-CGeel/90 px-3 py-2 w-max">STAN&nbsp;LINDERS</h2>
                        <p class="text-md text-CGeel bg-black/70 px-2 py-1 w-max ">Brand identity</p>
                        <p class="text-xs text-CGeel font-sans bg-CBlauw/80 px-2 py-1 w-max">Graphic design</p>
                    </div>
                      <router-link 
                      to="stanlinders_project" 
                      aria-label="Port-item" 
                      class="absolute text-left inset-0">
                      </router-link>
                  </div> 
                  <!-- easy2survey -->
                  <div class="relative overflow-hidden group">
                    <img src="../assets/projects/thumbs/easy_thumb.png" alt="Lamb_mockup" 
                        class="w-full transition duration-500 ease-in-out group-hover:opacity-60">
                    <div class="absolute text-left bottom-0 left-0 h-2/3 w-full flex flex-col justify-center p-5 space-y-1
                    transition duration-300 ease-in-out group-hover:scale-105">
                        <h2 class="text-6xl font-portcard bg-CGeel/90 px-3 py-2 w-max">easy survey</h2>
                        <p class="text-md text-CGeel bg-black/70 px-2 py-1 w-max">product design</p>
                        <p class="text-xs text-CGeel font-sans bg-CBlauw/80 px-2 py-1 w-max">web development</p>
                    </div>
                      <router-link 
                      to="/easy-project" 
                      aria-label="Port-item" 
                      class="absolute text-left inset-0">
                      </router-link>
                  </div> 
                  <!-- BROOD NBC -->
                  


                  <div class="relative overflow-hidden group">
                    <img src="../assets/projects/thumbs/nbc_thumb.png" alt="Lamb_mockup" 
                        class="w-full transition duration-500 ease-in-out group-hover:opacity-60">
                    <div class="absolute text-left bottom-0 left-0 h-2/3 w-full flex flex-col justify-center p-5 space-y-1
                    transition duration-300 ease-in-out group-hover:scale-105">
                        <h2 class="text-6xl font-portcard bg-CGeel/90 px-3 py-2 w-max">NEDERLANDS <br> BAKKERIJ <br> CENTRUM</h2>
                        <p class="text-md text-CGeel bg-black/70 px-2 py-1 w-max">Animatie video</p>
                        <p class="text-xs text-CGeel font-sans bg-CBlauw/80 px-1 py-1 w-max"> Storytelling&Animation </p>
                        </div>
                      <router-link 
                      to="nbc_project" 
                      aria-label="Port-item" 
                      class="absolute text-left inset-0">
                      </router-link>
                    </div>
                  <!-- hobo dumpsterdfiving -->
                  <div class="relative overflow-hidden group">
                      <img src="../assets/projects/thumbs/hobo_thumb.png" alt="Lamb_mockup" 
                          class="w-full transition duration-500 ease-in-out group-hover:opacity-60">
                      <div class="absolute text-left bottom-0 left-0 h-2/3 w-full flex flex-col justify-center p-5 space-y-1
                      transition duration-300 ease-in-out group-hover:scale-105">
                          <h2 class="text-6xl font-portcard bg-CGeel/90 px-3  py-2 w-max">Hobo <br/>Dumpster <br/> Diving</h2>
                          <p class="text-md text-CGeel bg-black/70 px-2  py-1 w-max">Gamedesign</p>
                          <p class="text-xs text-CGeel font-sans bg-CBlauw/80 px-2 py-1 w-max">Graphic design</p>
                      </div>
                      <router-link 
                        to="/hobo_project" 
                        aria-label="Port-item" 
                        class="absolute text-left inset-0">
                      </router-link>
                  </div>
                  <!--Opdebeen -->
                  <div class="relative overflow-hidden group">
                      <img src="../assets/projects/thumbs/opdebeen_thumb.png" alt="Lamb_mockup" 
                          class="w-full transition duration-500 ease-in-out group-hover:opacity-60">
                      <div class="absolute text-left bottom-0 left-0 h-2/3 w-full flex flex-col justify-center p-5 space-y-1
                      transition duration-300 ease-in-out group-hover:scale-105">
                          <h2 class="text-6xl font-portcard bg-CGeel/90 px-3  py-2 w-max">Weer op <br/> de Been</h2>
                          <p class="text-md text-CGeel bg-black/70 px-2  py-1 w-max">Audiovisueell</p>
                          <p class="text-xs text-CGeel font-sans bg-CBlauw/80 px-2 py-1 w-max">Graphic design</p>
                      </div>
                      <router-link 
                        to="/opdebeen_project" 
                        aria-label="Port-item" 
                        class="absolute text-left inset-0">
                      </router-link>
                  </div>

                  <!-- pijnvijcoach -->
                  <div class="relative overflow-hidden group">
                    <img src="../assets/projects/thumbs/pijnvrijcoach_thumb.png" alt="Lamb_mockup" 
                        class="w-full transition duration-500 ease-in-out group-hover:opacity-60">
                    <div class="absolute text-left bottom-0 left-0 h-2/3 w-full flex flex-col justify-center p-5 space-y-1
                    transition duration-300 ease-in-out group-hover:scale-105">
                        <h2 class="font-portcard bg-CGeel/90 px-3 py-2 w-max">De Pijn <br/> vrijcoach</h2>
                        <p class="text-md text-CGeel bg-black/70 px-2 py-1 w-max">Beeld&geluid</p>
                        <p class="text-xs text-CGeel font-sans bg-CBlauw/80 px-2 py-1 w-max">Animatie</p>
                    </div>
                      <router-link 
                      to="/pijnvrijcoach_project" 
                      aria-label="pijnvrijcoach_project" 
                      class="absolute text-left inset-0">
                      </router-link>
                  </div> 
                  <!-- etsy posters -->
                  <div class="relative overflow-hidden group">
                    <img src="../assets/projects/thumbs/thirsty_thumb.png" alt="Lamb_mockup" 
                        class="w-full transition duration-500 ease-in-out group-hover:opacity-60">
                    <div class="absolute text-left bottom-0 left-0 h-2/3 w-full flex flex-col justify-center p-5 space-y-1
                    transition duration-300 ease-in-out group-hover:scale-105">
                        <h2 class="text-6xl font-portcard bg-CGeel/90 px-3 py-2 w-max">Thirsty <br/>and dry</h2>
                        <p class="text-md text-CGeel bg-black/70 px-2 py-1 w-max">Etsy sidehustle</p>
                        <p class="text-xs text-CGeel font-sans bg-CBlauw/80 px-2 py-1 w-max">Commercial Design</p>
                    </div>
                      <router-link 
                      to="/etsy_project" 
                      aria-label="Port-item" 
                      class="absolute text-left inset-0">
                      </router-link>
                  </div> 
                  <!-- Assorted Animations -->
                  <div class="relative overflow-hidden group">
                    <img src="@/assets/projects/thumbs/AAnimationsThumb.png" alt="Lamb_mockup" 
                        class="w-full transition duration-500 ease-in-out group-hver:opacity-60">
                    <div class="absolute text-left bottom-0 left-0 h-2/3 w-full flex flex-col justify-center p-5 space-y-1
                    transition duration-300 ease-in-out group-hover:scale-105">
                        <h2 class="text-6xl font-portcard bg-CGeel/90 px-3 py-2 w-max">Assorted <br/>Animations</h2>
                        <p class="text-md text-CGeel bg-black/70 px-2 py-2 w-max">Selected work</p>
                        <p class="text-xs text-CGeel font-sans bg-CBlauw/80 px-2 py-2 w-max">Motion Graphics</p>
                    </div>
                      <router-link 
                      to="/assortedanimations_project" 
                      aria-label="" 
                      class="absolute text-left inset-0">
                      </router-link>
                  </div> 
                </div>
              
                  

                </div>
              <div class="pt-20"></div>
             
           </div>
          
      
      
            
          









</template>

<script>

export default {
  name: 'PortfolioCards',
  props: {
    msg: String,
  },
};


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h {
  margin: 50px 0 0;
  font-size:9rem;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  font-family: "heavitas", serif;
  font-weight:300;
}
</style>
