<template>
  <LandingPage/>
  <PortfolioCards/>
</template>

<script>
// @ is an alias to /src
import '@/assets/tailwind.css';
import LandingPage from '@/components/LandingPage.vue';
import PortfolioCards from '@/components/PortfolioCards.vue'

export default {
  name: 'HomeView',
  components: {
    LandingPage,
    PortfolioCards
  }
}
</script>







