<template>
  <nav class="bg-noise bg-CBlauw p-5 lg:p-3">
    <div class="container max-w-6xl mx-auto flex flex-col lg:flex-row justify-between items-center">
      
      <!-- Logo aligned to the left -->
      <router-link to="/">
        <img src="@/assets/FloringasLogo.png" class="w-0.5 invisible md:w-full md:visible inline-block md:max-w-40 p-2" />
      </router-link>

      <!-- Hamburger Button (Mobile) aligned to the right -->
      <button  
        @click="toggleMenu" 
        class="lg:hidden flex flex-col justify-around h-10 w-10 p-1 hover:bg-gray-400 ml-auto">
        <span class="h-1.5 bg-gray-200 w-full"></span>
        <span class="h-1.5 bg-gray-200 w-full"></span>
        <span class="h-1.5 bg-gray-200 w-full"></span>
      </button>

      <!-- Navigation Links -->
      <div 
        :class="{'hidden': !menuOpen, 'flex': menuOpen, 'lg:flex': true, 'lg:ml-auto': true}" 
        class="flex-col lg:flex-row lg:space-x-4 lg:mt-3 mt-5 items-center text-xl font-Heavitas text-center lg:justify-center"
      >
        <router-link 
          to="/" 
          aria-label="Home" 
          @click="closeMenu"
          class="py-2 flex justify-start w-auto hover:text-CRoodf transition ease-in-out delay-150 
                 border-b border-CGeel lg:border-none"
        >
          Home
        </router-link>

        <router-link 
          to="/projects" 
          aria-label="Projects" 
          @click="closeMenu"
          class="py-2 flex justify-start w-auto hover:text-CRoodf transition ease-in-out delay-150 
                 border-b border-CGeel lg:border-none"
        >
          Werk
        </router-link>

        <router-link 
          to="/about" 
          aria-label="About" 
          @click="closeMenu"
          class="py-2 flex justify-start w-auto hover:text-CRoodf transition ease-in-out delay-150 
                 border-b border-CGeel lg:border-none"
        >
          Over Mij
        </router-link>

        <!-- <router-link 
          to="/contact" 
          aria-label="Contact" 
          @click="closeMenu"
          class="py-2 flex justify-start w-auto hover:text-CRoodf transition ease-in-out delay-150 
                 border-b border-CGeel lg:border-none"
        >
          Contact
        </router-link>-->
      </div> 
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBar',
  props: {
    msg: String,
  },
  data() {
    return {
      menuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h {
  margin: 40px 0 0;
  font-size:9rem;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  
  font-family: heavitas, serif;
  font-weight:300;
  color:#fdfcdc;

}
</style>
