    <template>
    <div class="bg-noise">
      <div class="bg-CRoze bg-noise">
      <div class="max-w-6xl m-auto relative pt-10 pb-12 md:px-5 lg:px-0">
      <h1 class="text-center text-5xl md:text-left text-CBlauw">Weer op de<br> been erin</h1>
    <hr class="m-auto mt-3 w-48 h-2 mb-2 rounded-md bg-CRoodf md:m-0 md:mt-2">
                <p class="category text-center md:text-left ">Beeld&Geluid</p>
          <p class="text-center max-w-2xl md:text-left introtext pt-5">Na een motorongeluk in 2004 is het leven van Jaap de Haan (75) drastisch veranderd. 
            Ondanks de vele tegensla gen heeft hij zich nooit uit het veld laten slaan. <br><br> “Als je je hierdoor terugtrekt uit het sociale leven, dan ben je pas gehandicapt. 
            Ik heb de beperking altijd een beetje ontkend. Daardoor had ik minder moeite om ermee te leven” 
          </p>
        </div>
      </div>
      <div class="py-10 bg-noise">
        <div class="max-w-6xl m-auto relative md:px-5 lg:px-0">
          <div class="space-y-8 md:space-y-10 text-center md:text-left  md:justify-center md:items-center md:flex  ">
          <div class="w-full md:w-1/4 space-y-4  md:mr-10 lg:pb-40">
            <h3></h3>
                        <p>
                          In opdracht van Journalist Eva van Doesborgh heb ik de beeld- en geluidsopnames verzorgd, zodat zij zich volledig kon richten op de interviews. 
                          De mini-documentaire is nuchter en informatief van aard, met als doel Jaap op een menselijke manier in beeld te brengen terwijl hij zijn dagelijkse leven leidt.                        
                        </p>
                    </div>
              <div class="mx-auto w-full md:w-3/4">
               
              <video class="h-full w-full " controls>
      <source
        src="../../assets/projects/pijnvrijcoach/pijnvrijcoach.mp4"
        type="video/mp4"
      />

    </video>
              
            </div>
        </div>
            
        </div>
        </div>
    <div class="bg-CRoze py-10">
      <div class="max-w-6xl m-auto relative md:px-5 lg:px-0">
        <div class="space-y-8 md:space-y-0 text-center md:text-left md:justify-center  md:flex md:items-center md:flex-row-reverse ">
          <div class="w-full md:w-1/4 space-y-4  md:mr-10 lg:pb-40 md:ml-10">
          <h3></h3>
                        <p>
                          Naast het interview hebben we ook op diverse locaties filmmateriaal opgenomen. Om een objectieve en eerlijke weergave te garanderen, heb ik gekozen voor neutrale composities. 
                          Tevens heb ik, ter benadrukking van Jaaps handicap, meerdere opnamen op beenhoogte gemaakt.                        
                        </p>
                    </div>
            <div class="mx-auto w-3/4 md:w-3/4"  >
              <img class="w-full" src="../../assets/projects/opdebeen/weeropdebeen_screen.png">
              <div class="description-text-left">Stil uit de video.</div>
            </div>
          </div>
        </div>
        </div>
    
    </div>
    </template>

    <script>
    export default {
      name: 'opdebeen_project',
      props: {
        msg: String,
      },
    };
    </script>

    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    h {
      margin: 40px 0 0;
      font-size:9rem;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #634e3b;
      font-family: "Host Grotesk", serif;
    }
    </style>
