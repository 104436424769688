    <template>
    <div class="bg-noise">
      <div class="bg-CRoze bg-noise">
      <div class="max-w-6xl m-auto relative pt-10 pb-12 md:px-5 lg:px-0">
      <h1 class="text-center text-5xl md:text-left text-CBlauw">VIDEO NEDERLANDS BAKKERIJ CENTRUM</h1>
    <hr class="m-auto mt-3 w-48 h-2 mb-2 rounded-md bg-CRoodf md:m-0 md:mt-2">
                <p class="category text-center md:text-left ">Band identity / print</p>
          <p class="text-center max-w-2xl md:text-left introtext pt-5">Tijdens mijn studie Communicatie en Multimedia Design heb ik een volledige informatieve animatie ontwikkeld voor het Nederlands Bakkerij Centrum (NBC). 
            Het NBC signaleerde een daling in de broodconsumptie in Nederland, mede door verschillende misvattingen over brood.<br/> <br/>
            Het doel van de video was om deze misvattingen te weerleggen en de boodschap van "meer brood eten" op een overtuigende en aansprekende manier te communiceren.
          </p>
        </div>
      </div>
      <div class="py-10 bg-noise">
        <div class="max-w-6xl m-auto relative md:px-5 lg:px-0">
          <div class="space-y-8 md:space-y-10 text-center md:text-left  md:justify-center md:items-center md:flex  ">
          <div class="w-full md:w-1/4 space-y-4  md:mr-10 lg:pb-30">
            <h3>Strategie en aanpak</h3>
                        <p>
                          Voordat de productie van start ging, heb ik een uitgebreid onderzoek uitgevoerd om de feiten en percepties rondom broodconsumptie in kaart te brengen. 
                          Ik sprak met medewerkers van het NBC, diverse stakeholders en interviewde meerdere bakkers om te achterhalen wat zij zo bijzonder vinden aan brood. </p><p>
                          Deze inzichten vormden de basis voor het storyboard en het script, die ik vervolgens iteratief heb getest bij verschillende representatieve doelgroepen. 
                          Dit leverde een heldere blauwdruk op voor het uiteindelijke filmpje.                        
                        </p>
                    </div>
            <div class="mx-auto w-full">
              <video class="h-full w-full " controls>
      <source
        src="../../assets/projects/nbc/nbc_brood.mp4"
        type="video/mp4"
      />

    </video>
              
            </div>
        </div>
            
        </div>
        </div>
    <div class="bg-CRoze py-10">
      <div class="max-w-6xl m-auto relative md:px-5 lg:px-0">
        <div class="space-y-8 md:space-y-0 text-center md:text-left md:justify-center  md:flex md:items-center md:flex-row-reverse ">
          <div class="w-full md:w-1/4 space-y-4  md:mr-10 lg:pb-40 md:ml-10">
          <h3>De Uitwerking</h3>
                        <p>
                          Uitgaande van het storyboard heb ik alle prominente schermen verder uitgewerkt in Adobe Illustrator en deze vervolgens bewerkt in Photoshop om een authentieke broodtextuur toe te voegen. 
                          Vervolgens zocht ik een passende voice-over, die uiteindelijk werd ingesproken door Bregt, een goede vriend met aspiraties als radio-dj. </p><p>
                          Vanwege de coronamaatregelen was toegang tot een professionele studio beperkt, waardoor ik in mijn huiskamer een geïmproviseerde geluidsdichte ruimte creëerde met matrassen en dekbedden. Hierin zorgde ik ervoor dat Bregt het script tot in de perfectie inzette.
                          Met de voice-over en de high-fidelity stills heb ik de animatie in Adobe After Effects gerealiseerd. </p><p> Naast het werken met keyframes heb ik verschillende scripts ingezet om dynamische elementen zoals cijfers, bewegende lijnen en zelfs vuur te visualiseren.                        </p>
                    </div>
            <div class="mx-auto w-3/4"  >
              <img class="w-full" src="../../assets/projects/nbc/nbc_screen1.png"/>
              <div class="description-text-left">Still uit de video</div>
            </div>
          </div>
        </div>
        </div>

        <div class="py-10 bg-noise">
        <div class="max-w-6xl m-auto relative md:px-5 lg:px-0">
          <div class="space-y-8 md:space-y-10 text-center md:text-left  md:justify-center md:items-center md:flex  ">
          <div class="w-full md:w-1/4 space-y-4  md:mr-10 lg:pb-40">
            <h3>Resultaat en impact</h3>
                        <p>
                          Het eindresultaat is een informatieve en dynamische video die de kijker boeit en overtuigt. 
                          Het NBC gebruikt de video inmiddels voor interne doeleinden, waardoor het direct meten van de impact op de broodconsumptie uitdagend blijft.
                        </p>
                    </div>
            <div class="mx-auto w-3/4">
              <img class="w-full" src="../../assets/projects/nbc/nbc_screen2.png"/>
              <div class="description-text-right">Still uit de video.</div>
            </div>
        </div>
            
        </div>
        </div>
    
    </div>
    </template>

    <script>


    export default {
      name: 'nbc_project',
      props: {
        msg: String,
      },
    };
    </script>

    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    h {
      margin: 40px 0 0;
      font-size:9rem;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #634e3b;
      font-family: "Host Grotesk", serif;
    }
    </style>
