<template>
<div class="bg-noise">
    <div class="max-w-6xl mx-auto">
        <h1 class=" text-6xl pt-10 pb-2 text-CBlauw ">contact</h1>
        <div class="max-w-6xl mx-autosm:px-6 pt-10 pb-16 grid md:grid-cols-2 lg:grid-cols-2 gap-y-8 md:gap-x-8 md:gap-y-8 lg:gap-x-8 ">
        <div class="mx-auto">
            <p>Geïnteresseerd in mijn diensten, een passende vacature, of gewoon even gedag zeggen? Stuur me gerust een berichtje!</p>
            <div class="flex items-center mt-8 space-x-2 text-dark-600 ">
                <span>Omgeving Utrecht</span>
            </div>
            <p>
               
                <a href="mailto:floriwierenga@gmail.com">Floriswierenga@gmail.com</a>
            </p>
          
           
        </div>
        <div>
            <div class=" md:px-0 md:pt-1">
            <form>
                <input type="checkbox" id="" class="hidden" style="display:none" name="botcheck">
                <div class="mb-3">
                    <input type="text" placeholder="Naam" autocomplete="false"
                        class="w-full px-4 py-3 border-2 placeholder:text-gray-600 outline-none  focus:ring-4   border-CBlauw focus-CBlauw ring-echtroze"
                        name="name">
                </div>
                <div class="mb-5">
                    <label for="email_address" class="sr-only">Email Address</label>
                    <input id="email_address" type="email" placeholder="Email Adres" autocomplete="true"
                        class="w-full px-4 py-3 border-2 placeholder:text-gray-600 outline-none focus:ring-4 h-10 border-CBlauw focus:border-gray-600 ring-echtroze"
                        name="email">
                </div>
                <div class="mb-3">
                    <label for="Message" class="sr-only">Message</label>
                    <textarea placeholder="Your Message" type="message" 
                        class="w-full px-4 py-3 border-2 placeholder:text-gray-700 outline-none h-20 focus:ring-4 border-CBlauw focus:border-gray-600 ring-echtroze"
                        name="message">
                    </textarea>
                </div>
                <button type="submit"
                    class="w-full py-4 text-CBlauw font-semibold border-2 border-CBlauw hover:hover:bg-echtroze hover:text-white transition ease-in-out  ">Versturen
                </button>
            </form>
        </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
export default {
  name: 'ContactForm',
  props: {
    msg: String,
  },
};
</script>
