<template>
   <InformationSkills/>
</template>


<script>
// @ is an alias to /src
import '@/assets/tailwind.css';
import InformationSkills from '@/components/InformationSkills.vue';

export default {
  name: 'AboutView',
  components: {
   InformationSkills,
  }
}
</script>



