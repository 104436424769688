import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue';
import PortfolioCards from '@/components/PortfolioCards.vue';
import stanlinders_project from '@/components/projects/stanlinders_project.vue';
import pijnvrijcoach_project from '@/components/projects/pijnvrijcoach_project.vue';
import ContactForm from '@/components/ContactForm.vue';
import OpdebeenView from '@/views/opdebeenView.vue';
import NbcView from '@/views/nbcView.vue';
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about me',
    component: AboutView
  },
  {
    path: '/projects',
    name: 'projects enz',
    component: PortfolioCards
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactForm
  },

  {
    path: '/stanlinders_project',
    name: 'stanlinders_project',
    component: stanlinders_project
  },

  {
    path: '/pijnvrijcoach_project',
    name: 'pijnvrijcoach_project',
    component: pijnvrijcoach_project
  },

  {
    path: '/opdebeen_project',
    name: 'popdebeen_project',
    component: OpdebeenView
  },

  {
    path: '/nbc_project',
    name: 'nbc_project',
    component: NbcView
  },
  
  {
    path: '/lamb_project',
    name: 'lamb_project',
    component: () => import(/* webpackChunkName: "stanlinders_project" */ '../views/lambView.vue')
  },

  {
    path: '/easy-project',
    name: 'easy_project',
    component: () => import(/* webpackChunkName: "stanlinders_project" */ '../views/easyView.vue')

  },


{
  path: '/assortedanimations_project',
  name: 'assortedanimations_project',
  component: () => import(/* webpackChunkName: "stanlinders_project" */ '../views/assortedanimationsView.vue')

},

{
  path: '/hobo_project',
  name: 'hobo_project',
  component: () => import(/* webpackChunkName: "stanlinders_project" */ '../views/hoboView.vue')

},

{
  path: '/etsy_project',
  name: 'etsy_project',
  component: () => import(/* webpackChunkName: "stanlinders_project" */ '../views/etsyhustleView.vue')

},
];







const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // If user navigates back, restore saved position
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" }; // Scroll to element with the ID matching the hash
    }
    return { top: 0, left: 0, behavior: "smooth" }; // Ensure top of page
  },
});


export default router
