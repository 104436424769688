<template>
<nbc_project/>
</template>


<script>
// @ is an alias to /src
import '@/assets/tailwind.css';
import nbc_project from '@/components/projects/nbc_project.vue';


export default {
  name: 'pijnvrijcoach_project',
  components: {
   nbc_project
  }
}
</script>



